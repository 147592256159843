import axios from "axios";

// const httpClient = axios.create({
//   baseURL: "http://localhost:8009/admin",
// });

const httpClient = axios.create({
  baseURL: "https://api.제이플러스.com/admin",
});

httpClient.interceptors.request.use(
  (config) => {
    config.headers!["admin-key"] = localStorage.getItem("KEY") ?? "";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

httpClient.interceptors.response.use(
  (res) => res,
  (err) => {
    window.localStorage.clear();
    window.location.reload();

    return Promise.reject(err);
  }
);

export default httpClient;
